import '@babel/polyfill';
import 'es6-promise/auto';
import 'intersection-observer';
import Vue from 'vue'
import mixin from './components/mixin';
import commonMethods from './components/commonMethods';
import imageSlider from './components/imageSlider.vue';
import intersectionWrap from './components/intersectionWrap.vue';
import pageLoading from './components/pageLoading.vue';
import baguetteBox from 'baguettebox.js';
import spinnerLoading from './components/spinnerLoading.vue';
import getCodeByAjax from './components/getCodeByAjax.vue';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';
//--------------------------------------------------------------------
Vue.component('image-slider',imageSlider);
Vue.component('intersection-wrap',intersectionWrap);
Vue.component('page-loading',pageLoading);
Vue.component('get-code-by-ajax',getCodeByAjax);
Vue.component('spinner-loading',spinnerLoading);
//--------------------------------------------------------------------

const init = () => {

	new Vue({
		el:'#app',
		data:{
			siteName:'武井香璋(Takei Koushou)　オフィシャルサイト',
			version:'1.0.0',
		},
		mixins:[
			mixin,
			commonMethods,
		],
		created:function(){
			this.init();
		},
		mounted:function(){
			baguetteBox.run('.bbGallery');
		},
		methods:{
			init:function(){
				this.setScrollBorder(120);
			}
		},
	});

};

window.addEventListener('DOMContentLoaded',init,false);

//--------------------------------------------------------------------

