<template>
	<div :class="{active:active}">
		<spinner-loading v-if="loading"/>
		<div @click="clickFunc">
			<slot name="trigger"></slot>
		</div>
		<div v-if="loaded" v-html="loadData" class="accoContent"></div>
	</div>
</template>


<script>

import ajax from '../api/ajax';

export default {
	name:'getCodeByAjax',
	data:function(){
		return {
			requestPath:'/ajax/ajax.php',
			tokun:null,
			ajax:null,
			loadData:'',
			loading:false,
			loaded:false,
			active:false,
		};
	},
	mounted:function(){
		this.setToken();
		this.initAjax();
		this.setDefaultState();
	},
	props:['dataKey','defaultState'],
	methods:{
		setDefaultState:function(){
			if(this.defaultState !== 'active') return;
			this.clickFunc();
		},
		setToken:function(){
			this.tokun = document.querySelector('meta[name="csrf-token"]').content;
		},
		initAjax:function(){
			ajax.setRequestPath(this.requestPath);
			ajax.setAjaxType('getCode');
			ajax.setToken(this.tokun);
			this.ajax = ajax;
		},
		sendPost: async function(data){
			if(this.defaultState !== 'active') this.loading = true;
			const res = await this.ajax.post(this.ajax.requestPath,data);
			this.loading = false;
			const flag = (this.checkAjaxResponse(res)) ? true : false;
			return {flag:flag,data:res};
		},
		checkAjaxResponse:function(res){
			if(res === undefined ||
				res.data == null ||
				res.data.data == null ||
				res.data.data.queryType == null ||
				res.data.data.result == null
			){
				return false;
			}
			return true;
		},
		getResponseData:function(res){
			if(!this.checkAjaxResponse(res)) return false;
			const responseData = {
				queryType:res.data.data.queryType,
				result:res.data.data.result,
				table:res.data.data.table,
				postKey:res.data.data.postKey,
			};
			return responseData;
		},
		getCode: async function(){
			const data = {
				queryType:'archive',
				queryInfo:{
					dataKey:this.dataKey,
				}
			}

			const resObj = await this.sendPost(data);
			if(!resObj.flag){
				console.log('error',resObj);
				return;
			}

			const respons = this.getResponseData(resObj.data);
			this.loadData = respons.result;
			this.loaded = true;
		},
		clickFunc: async function(){
			if(!this.loaded) await this.getCode();
			const x = window.pageXOffset;
			const y = window.pageYOffset;
			this.active = !this.active;
			await this.$nextTick();
			window.scrollTo(x,y);
		},
	}
}

</script>